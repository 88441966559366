<template>
  <section class="section section_votes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg title_svg-show pb-4">Опросы
            <router-link :to="{name: 'Votes'}">
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_226_668)">
                  <circle cx="19" cy="18" r="18" transform="rotate(-90 19 18)" fill="#59B980"/>
                </g>
                <path
                    d="M15.779 27.0592L16.0039 27.1096L16.1883 26.9713L24.3651 20.8412L24.3659 20.8406C25.6264 19.8918 26.3574 18.7758 26.3574 17.5713C26.3574 16.3668 25.6264 15.2508 24.3659 14.302L24.3651 14.3014L16.1883 8.17123L16.0039 8.03298L15.779 8.0834L15.4623 8.15441L14.4039 8.3917L15.2718 9.04235L23.4482 15.1723C24.5481 15.9984 24.9975 16.8291 24.9975 17.5713C24.9975 18.3135 24.5482 19.1441 23.4484 19.9702C23.4483 19.9702 23.4482 19.9703 23.4482 19.9703L15.2718 26.1002L14.4039 26.7509L15.4623 26.9882L15.779 27.0592Z"
                    fill="white" stroke="white"/>
                <defs>
                  <filter id="filter0_d_226_668" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_226_668"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_226_668" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </router-link>
          </h2>
        </div>
      </div>
      <div class="row d-flex align-items-stretch">
        <div class="col-12 col-md-3">
          <div class="vote__top">
            <vote-item :text="'Следующий опрос'" :vote="other" :image="vote.image"></vote-item>
          </div>
          <div class="vote__bottom"></div>
        </div>
        <div class="col-12 col-md-6 pt-2">
          <div class="vote__title">{{ vote.name }}</div>
          <div class="vote__wrapper mt-2">
            <validation-observer tag="form" @submit.prevent="sendForm" v-slot="{valid }">
              <validation-provider tag="div" :rules="'required'" v-slot="{errors, touched}" class="vote__item"
                                   v-for="(question, index) in getVoteVariant" :key="question.id"
              >
                <div class="vote__name">{{ index + 1 }}. {{ question.name }}</div>
                <div class="vote__variants">
                  <div class="row">
                    <div class="col-12 col-md-12 pb-1" v-for="variant in question.VoteVariants" :key="variant.id">
                      <label :for="`input-${variant.id}`" class="vote__variant d-flex">
                        <input type="radio" :id="`input-${variant.id}`" v-model="variants[question.id]"
                               :value="variant.id">
                        <span>{{ variant.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="vote__errors" v-if="errors.length && touched">
                  {{ errors[0] }}
                </div>
              </validation-provider>
              <div class="row">
                <div class="col-12 text-end">
                  <button :disabled="!valid" class="vote__button">Завершить опрос</button>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import VoteItem from "../components/components/VoteItem";

export default {
  name: "VotesItem",
  components: {VoteItem},
  data() {
    return {
      vote: {},
      variants: {},
      other: {},
    }
  },
  computed: {
    getVoteVariant() {
      const questions = _.filter(this.vote.VoteQuestions, item => item.VoteVariants.length)
      _.forEach(questions, question => {
        question.VoteVariants = _.orderBy(question.VoteVariants, ['order'])
      })
      return _.orderBy(questions, ['order'])
    },
    getVoteId() {
      return this.$route.params.id
    }
  },
  watch: {
    getVoteId() {
      this.other = null
      this.loadVote()
      this.loadOtherVote()
    }
  },
  methods: {
    loadVote() {
      this.$axios.get(`votes/${this.$route.params.id}`)
          .then(resp => {
            this.vote = resp.data
            for (let question of resp.data.VoteQuestions) {
              this.variants[question.id] = null
            }
            if (this.vote.user) {
              this.$router.push('/votes')
            }
          })
          .catch(e => {
            console.log(e)
            this.$router.push('/votes')
          })
    },
    sendForm() {
      const payload = {
        variants: _.filter(Object.values(this.variants), item => item),
        id: this.vote.id
      }
      this.$axios.post('votes', payload)
          .then(() => {
            this.$router.push('/votes')
          })
          .catch(e => {
            console.log(e)
          })
    },
    loadOtherVote() {
      this.$axios.get('votes/other/' + this.$route.params.id)
          .then(resp => {
            this.other = resp.data
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
  mounted() {
    this.loadVote()
    this.loadOtherVote()
  },
}
</script>

<style scoped lang="scss">
.vote {
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: rem(32);
    line-height: 1.2;
    color: #00476D;
  }

  &__item {
    margin-top: rem(20);

    &:first-child {
      margin-top: rem(0);
    }
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: rem(14);
    line-height: 1.5;
    color: #1B252C;
  }

  &__wrapper {
    background: #FAFCFF;
    border-radius: 10px;
    padding: rem(35);
  }

  &__variants {
    padding-top: rem(9);
  }

  &__variant {
    span {
      display: inline-block;
      padding-left: rem(10);
      font-size: rem(14);
      color: #1B252C;
    }
  }

  &__errors {
    color: red;
    font-size: rem(12);
    padding-top: rem(5);
  }

  &__button {
    color: #0079C0;
    text-transform: uppercase;
    font-size: rem(14);
    border: 1px solid #0079C0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: rem(10) rem(15);
    margin-top: rem(25);
    background: transparent;

    &:disabled {
      border-color: #D3D3D3;
      color: #D3D3D3;
    }
  }
}

.title {
  line-height: 1;
}

.section_votes {
  padding: rem(30) 0;
}
</style>