<template>
  <div class="votes d-flex flex-column justify-content-between align-items-stretch" :class="vote.user ? 'votes_disabled' : ''">
    <div class="votes__image">
      <img :src="image ? image: vote.image" :alt="vote.name">
    </div>
    <div class="votes__link pt-4 text-end" v-if="vote.id">
      <router-link :to="{name: 'VotesItem', params: {id: vote.id}}" :is="vote.user ? 'span' : 'router-link'">
        {{text ? text : vote.user ? 'Опрос пройден' : 'Пройти опрос' }}
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_710_502)">
            <circle cx="13" cy="12" r="12" transform="rotate(-90 13 12)" fill="#59B980"/>
          </g>
          <path d="M10.8696 17.9583L10.9821 17.9835L11.0743 17.9144L16.5254 13.8276L16.5258 13.8273C17.3552 13.203 17.8203 12.4808 17.8203 11.7144C17.8203 10.9479 17.3552 10.2257 16.5258 9.6014L16.5254 9.60111L11.0743 5.51433L10.9821 5.4452L10.8696 5.47041L10.6585 5.51775L10.1293 5.6364L10.5632 5.96172L16.0142 10.0484C16.7585 10.6074 17.0804 11.183 17.0804 11.7144C17.0804 12.2457 16.7585 12.8213 16.0142 13.3803L10.5632 17.467L10.1293 17.7923L10.6585 17.911L10.8696 17.9583Z" fill="white" stroke="white" stroke-width="0.5"/>
          <defs>
            <filter id="filter0_d_710_502" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="0.5"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_710_502"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_710_502" result="shape"/>
            </filter>
          </defs>
        </svg>

      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoteItem",
  props: ['vote', 'image', 'text']
}
</script>

<style scoped lang="scss">
.votes {
  height: 100%;
  padding: rem(25) rem(40);
  position: relative;
  margin-bottom: rem(30);
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  &_disabled {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(grey, .4);
    }
  }
  &__image {
    img {
      max-width: 100%;
      vertical-align: top;
    }
  }
  &__link {
    a {
      text-decoration: none;
      color: #000;
      font-size: rem(12);
      svg {
        margin-left: rem(10);
        max-width: 30px;
        height: auto;
      }
    }
  }
}
</style>